<template>
  <el-dialog
    append-to-body
    width="800px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop='name' label='用工单位：'>
            <el-input
              v-model='form.data.name'
              clearable
              placeholder='请输入用工单位'
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='credit_code' label='社会信用代码：'>
            <el-input
              v-model='form.data.credit_code'
              clearable
              placeholder='请输入社会信用代码'
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='tax_point' label='税率：'>
            <el-input
              v-model.number='form.data.tax_point'
              placeholder='请输入税率'
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='invoice_company_id' label='开票公司：'>
            <el-select v-model="form.data.invoice_company_id" placeholder="请选择" clearable filterable>
              <el-option
                v-for="item in invoiceCompanyOpt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='manager_fee' label='管理费金额：'>
            <el-input
              v-model='form.data.manager_fee'
              placeholder='请输入管理费金额'
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='manager_fee_type' label='承担人员：'>
            <el-select v-model="form.data.manager_fee_type" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='salary' label='基本工资：'>
            <el-input
              v-model='form.data.salary'
              placeholder='请输入基本工资'
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='allowance' label='补贴：'>
            <el-input
              v-model='form.data.allowance'
              placeholder='请输入补贴'
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='welfare' label='福利：'>
            <el-input
              v-model='form.data.welfare'
              placeholder='请输入福利'
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='settlement_type' label='薪资结算：'>
            <el-select v-model="form.data.settlement_type" placeholder="请选择">
              <el-option
                v-for="item in settlementType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='settlement_day' label='发薪日：'>
            <el-input-number v-model="form.data.settlement_day"
              :min="1"
              :max="31"
              controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='full_freq_days' label='满勤天数：'>
            <el-input-number v-model="form.data.full_freq_days"
              :min="1"
              :max="31"
              controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='contract_end_day' label='合同日期：'>
            <el-date-picker
              v-model="form.data.contract_end_day"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop='mark' label='备注：'>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
              v-model="form.data.mark">
            </el-input>
           </el-form-item>
        </el-col>
      </el-row> 
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { invoiceCompanyList } from '@/api/finace.js'
  import common from '@/util'
  export default {
    name: 'SystemUnitEdit',
    data() {
      var checkRate = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入税率'));
        }
        if (!Number.isInteger(value)) {
          return callback(new Error('税率只能输入数字'));
        } else {
          callback();
        }
      };
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            credit_code: '',
            tax_point: 5,
            invoice_company_id: '',
            manager_fee: ' ',
            manager_fee_type: '',
            salary: '',
            allowance: '',
            welfare: '',
            settlement_type: '',
            settlement_day: '',
            full_freq_days: '',
            contract_end_day: '',
            mark: '',
          },
          rules: {
            name: [{ required: true, message:'请输入', trigger: 'change'}],
            tax_point: [{ validator:checkRate, required: true, trigger: 'blur'}],
            invoice_company_id: [{ required: true, message:'请输入', trigger: 'change'}],
            manager_fee: [{ required: true, message:'请输入', trigger: 'change'}],
            manager_fee_type: [{ required: true, message:'请输入', trigger: 'change'}],
            salary: [{ required: true, message:'请输入', trigger: 'change'}],
            allowance: [{ required: true, message:'请输入', trigger: 'change'}],
            welfare: [{ required: true, message:'请输入', trigger: 'change'}],
            settlement_type: [{ required: true, message:'请输入', trigger: 'change'}],
            settlement_day: [{ required: true, message:'请输入', trigger: 'change'}],
            full_freq_days: [{ required: true, message:'请输入', trigger: 'change'}],
            contract_end_day: [{ required: true, message:'请输入', trigger: 'change'}],
          }
        },
        optOrgType: [],
        optOrg: [],
        options: [{
          value: 1,
          label: '企业'
        },
        {
          value: 2,
          label: '员工'
        }],
        settlementType: [
          {
            value: 1,
            label: '当月薪资'
          },
          {
            value: 2,
            label: '上月薪资'
          }
        ],
        invoiceCompanyOpt: [], // 开票公司
      }
    },
    created() {
      // this.getBaseInfo()
    },
    methods: {
      // 获取开票公司
      getBaseInfo() {
        invoiceCompanyList().then(res => {
          this.invoiceCompanyOpt = res.data.list;
        })
      },
      // 获取企业详情
      getDetail(row) {
        this.isChange = true
        this.$http.post('/admin/company/detail', { id: row.id }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
        
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           console.log(this.form.data);
           this.form.loading = true;
           let apiUrl = !this.isChange ? '/admin/company/create' : '/admin/company/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success("操作成功！")
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
