<template>
  <el-dialog
    append-to-body
    width="1000px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <p>缴纳月份： {{detailInfo.month}}月</p>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des">用工单位</td>
            <td colspan="4">{{ detailInfo.company_name }}</td>
            <td class="des">员工姓名</td>
            <td colspan="4">{{ detailInfo.employee_name }}</td>
          </tr>
          <tr>
            <td class="des" >联系电话</td>
            <td colspan="4">{{ detailInfo.phone }}</td>
            <td class="des">性别</td>
            <td colspan="4">{{ detailInfo.sex === 1 ? '男' : '女' }}</td>
          </tr>
          <tr>
            <td class="des">缴纳五险</td>
            <td colspan="2">{{ detailInfo.is_insurance === 1 ? '是' : '否' }}</td>
            <td class="des">选择档次</td>
            <td colspan="2">{{ detailInfo.insurance_level_name }}</td>
            <td class="des">缴纳方式</td>
            <td colspan="3">{{ payType[detailInfo.insurance_pay_type] }}</td>
          </tr>
          <tr>
            <td class="des">缴纳公积金</td>
            <td colspan="2">{{ detailInfo.is_fund === 1 ? '是' : '否' }}</td>
            <td class="des">缴纳基数</td>
            <td colspan="2">{{ detailInfo.fund_level_base }}元;{{detailInfo.fund_level_company_rate}}%,{{detailInfo.fund_level_employees_rate}}%</td>
            <td class="des">缴纳方式</td>
            <td colspan="3">{{ payType[detailInfo.fund_pay_type] }}</td>
          </tr>
          <tr>
            <td class="des">雇主责任险</td>
            <td colspan="2">{{ detailInfo.is_duty === 1 ? '是' : '否' }}</td>
            <td class="des">单位部分</td>
            <td colspan="2">{{ detailInfo.company_duty_fee }}元</td>
            <td class="des">员工部分</td>
            <td colspan="3">{{ detailInfo.person_duty_fee }}元</td>
          </tr>
          <!-- 只有一月份才显示大病医疗险 -->
          <tr v-if="detailInfo.month === 1">
            <td class="des">大病医疗险</td>
            <td colspan="2">{{ detailInfo.is_illness === 1 ? '是' : '否' }}</td>
            <td class="des">单位部分</td>
            <td colspan="2">{{ detailInfo.company_illness_fee }}元</td>
            <td class="des">员工部分</td>
            <td colspan="3">{{ detailInfo.person_illness_fee}}元</td>
          </tr>
        </tbody>
      </table>

      <!-- 判断是否缴纳五险 -->
      <template v-if="detailInfo.is_insurance === 1">
        <p class="table-title"> 五险信息：单位部分  {{detailInfo.company_insurance_fee}}元   员工部分 {{detailInfo.person_insurance_fee}}元    合计 {{detailInfo.insurance_total}}元</p>
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
          <tbody>
            <tr>
              <td class="des">养老保险基数</td>
              <td colspan="2">{{ detailInfo.endowment_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.endowment_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.endowment_employees_rate }}%</td>
            </tr>
            <tr>
              <td class="des">失业保险基数</td>
              <td colspan="2">{{ detailInfo.birth_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.birth_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.birth_employees_rate }}%</td>
            </tr>
            <tr>
              <td class="des">补充工伤险</td>
              <td colspan="2">{{ detailInfo.extra_injury_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.extra_injury_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.extra_injury_employees_rate}}%</td>
            </tr>
            <tr>
              <td class="des">工伤保险基数</td>
              <td colspan="2">{{ detailInfo.injury_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.injury_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.injury_employees_rate }}%</td>
            </tr>
            <tr>
              <td class="des">医疗保险基数</td>
              <td colspan="2">{{ detailInfo.medical_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.medical_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.medical_employees_rate }}%</td>
            </tr>
            <tr>
              <td class="des">生育保险基数</td>
              <td colspan="2">{{ detailInfo.birth_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.birth_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.birth_employees_rate }}%</td>
            </tr>
            <tr>
              <td class="des">大病医疗险基数</td>
              <td colspan="2">{{ detailInfo.illness_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.illness_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.illness_employees_rate }}%</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <p class="table-title">五险信息: 无</p>
      </template>

      <!-- 判断是否缴纳公积金 -->
      <template v-if="detailInfo.is_fund === 1">
        <p class="table-title">公积金信息：单位部分  {{detailInfo.company_accumulation_fee}}元   员工部分 {{detailInfo.person_accumulation_fee}}元    合计 {{detailInfo.fund_total}}元</p>
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
          <tbody>
            <tr>
              <td class="des">公积金基数</td>
              <td colspan="2">{{ detailInfo.fund_level_base }}元</td>
              <td class="des">单位占比</td>
              <td colspan="2">{{ detailInfo.fund_level_company_rate }}%</td>
              <td class="des">员工占比</td>
              <td colspan="3">{{ detailInfo.fund_level_employees_rate }}%</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        公积金信息: 无
      </template>

    </diV> 
  </el-dialog>
</template>
<script>
  export default {
    name: 'PerformanceDrawDetail',
    data() {
      return {
        visible: false,
        detailInfo: {},
        managerFeeType: {
          1:'企业',
          2:'员工'
        },
        settlementType: {
          1:'当月薪资',
          2:'上月薪资'
        },
        payType: {
          1: '单位全额',
          2: '员工全额',
          3: '单位员工各自缴纳'
        },
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        console.log(row);
        if(!!row) {
          this.$http.post('/admin/finance/paymentDetail', { id: row.id }).then(res => {
            if(res.code === 1) {
             this.detailInfo = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
     
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap {
  /* overflow-y: hidden; */
}
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}
.tableWrap .des {
  width: 90px;
}
.table-title {
  margin-top: 30px
}

</style>
